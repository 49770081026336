import { render, staticRenderFns } from "./MnemonicBackupWarning.vue?vue&type=template&id=efd5f37e&scoped=true&"
import script from "./MnemonicBackupWarning.vue?vue&type=script&lang=js&"
export * from "./MnemonicBackupWarning.vue?vue&type=script&lang=js&"
import style0 from "./MnemonicBackupWarning.vue?vue&type=style&index=0&id=efd5f37e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efd5f37e",
  null
  
)

export default component.exports